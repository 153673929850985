import React, { useEffect, useState } from "react";
import { Form, Container, Row, Col, FloatingLabel } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Session from "../component/Session";
import ErrorMessage from "../component/ErrorMessage";
import helper from "../utils/helper";
import apiPath from "../utils/apiPath";
import { useNavigate } from "react-router-dom";
import useToastContext from "../hooks/useToastContext";
import { PageLoader } from "../component/PageLoader";

const Profile = () => {
  const user = Session.getSession("user");
  const [image, setImage] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoader, setIsLoader] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const notification = useToastContext();
  const navigate = useNavigate()
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      name: profile?.name || "",
      mobile: profile?.mobile || "",
      email:profile?.email
    },
  });

  const handleCopyClick = () => {
    const textArea = document.createElement('textarea');
    textArea.value = `https://stellaredgecapital.com/login?code=${user?.referralCode}`;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
      window.alert("Referral code copied successfully.");
    } catch (err) {

    }

    document.body.removeChild(textArea);
  };

  const onSubmit = async (data) => {
    setIsLoader(true)
    let payload = new FormData()
    payload.append("mobile", data?.mobile)
    payload.append("countryCode", data?.countryCode)
    payload.append("name", data?.name)
    if (selectedImage) {
      payload.append("profilePic", selectedImage)
    }

    try {
      const res = await helper.api(apiPath.updateProfile, 'put', payload)

      if (res?.success) {
         setIsLoader(false)
        Session.setSession("user", res?.results)
        window.alert(res?.message);
        navigate('/')

      } else {
         setIsLoader(false)
        window.alert(res?.message);
        return false
      }
    } catch (error) {
       setIsLoader(false)
      console.log("error", error.message);
    }
  };

  const getProfile = async () => {
    setIsLoader(true)
    try {
      const res = await helper.api(apiPath.profile, 'get', {  })

      if (res?.success) {
        setIsLoader(false)
        Session.setSession("user", res?.results)
        setProfile(res?.results)
        setValue('email', res?.results?.email);
        setValue('mobile', res?.results?.mobile);
        setValue('name', res?.results?.name);
      } else {
        setIsLoader(false)
        window.alert(res?.message);
        return false
      }
    } catch (error) {
      setIsLoader(false)
      console.log("error", error.message);
    }

  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file)
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(()=>{
    getProfile()
  },[])

  return (
    <>
      <section className="contactPage py-4 py-lg-5">
        <Container>
          <div className="profileWrapper">
            <div className="bg-white box-shaddow">
              <div className="contact_us mt-4">

                {/* <h4 className="fw-500 text-dark mb-3">Profile</h4> */}

                <Form onSubmit={handleSubmit(onSubmit)}>

                  <div className=" text-center profileHead">
                    <div className="ProfilePic mb-4">
                      <figure className="mx-auto">
                        <span className="picFrem">
                          <img src={image ? image : profile?.profilePic} alt="profile" />
                        </span>
                        <span className="editPic">
                          <input type="file" onChange={handleImageChange} />
                          <img src="images/EditShape.svg" alt="edit" />
                        </span>
                      </figure>
                    </div>

                    <div className="userBalance">
                      <strong className="d-block mb-2" style={{ fontSize: "20px" }}><span style={{ fontWeight: "normal" }}>Balance</span> ${profile?.wallet}</strong>
                      <div> <button type="" className="themeBtn btn btn-primary py-2" style={{ fontSize: "14px" }}>
                        Submit Profile
                      </button></div>
                    </div>
                  </div>

                  <div className=" p-4">
                    <Row>
                    <Col lg={12}>
                        <Form.Group className="mb-4" controlId="formBasicName">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={true}
                            {...register("email", {
                            })}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg={12}>
                        <Form.Group className="mb-4" controlId="formBasicName">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter name"
                            {...register("name", {
                              required: "Please enter a name.",
                              minLength: {
                                value: 4,
                                message: "Name should contain at least 4 characters.",
                              },
                              maxLength: {
                                value: 30,
                                message: "Name should contain a maximum of 30 characters.",
                              },
                            })}
                          />
                          <ErrorMessage message={errors?.name?.message} />
                        </Form.Group>
                      </Col>

                      <Col lg={12}>
                        <Form.Group className="mb-4">
                          <Form.Label>Mobile Number/Country Code</Form.Label>
                          <div className="contact_num2 align-items-center text-blue mb-4">
                            {/* <Form.Select
                            aria-label="Select country code"
                            className="w-25 border-0"
                            {...register("countryCode", {
                              required: "Please select a country code.",
                            })}
                          >
                            <option value="+91">+91</option>
                          </Form.Select> */}
                            <FloatingLabel controlId="floatingInput" label="Contact No*" className="w-100 p-0">
                              <Form.Control
                                type="number"
                                placeholder="Enter mobile number"
                                {...register("mobile", {
                                  required: "Please enter a mobile number.",
                                  minLength: {
                                    value: 10,
                                    message: "Mobile number should contain at least 10 digits.",
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: "Mobile number should not exceed 10 digits.",
                                  },
                                })}
                              /> 
                            </FloatingLabel>
                            <ErrorMessage message={errors?.mobile?.message} />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>



                     {profile?.subscriptionType&&       <div className="Copy-Referral mb-4 pointer" onClick={handleCopyClick}>

                      <p><img src="images/copyicon.png" alt="copyIcon-copy" /> Copy Referral Code</p>
                    </div>}
                    <button type="submit" className="themeBtn btn btn-primary">
                      Submit Profile
                    </button>

                  </div>
                </Form>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <button
        onClick={() => navigate('/withdrawal')}
        type="button"
        className="themeBtn btn btn-primary"
        style={{ margin: '0' }} // Optional: remove default margin if needed
      >
        Withdrawal
      </button>
      <button
        onClick={() => navigate('/withdrawal-history')}
        type="button"
        className="themeBtn btn btn-primary"
        style={{ margin: '0' }} // Optional: remove default margin if needed
      >
        Withdrawal History
      </button>
    </div>
              </div>
            </div>
          </div>
        </Container>
        {isLoader&&<PageLoader/>}
      </section>
    </>
  );
};

export default Profile;
