import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Session from './Session'

export const Package = () => {

    const navigate = useNavigate()
    const user = Session.getSession("user");

    const handleDeposit = (route) => {
        if (!user) {
            window.alert('Please log in first.')
            navigate('/login')
            return
        }
        navigate(`/deposit`, { state: route })
    }
    return (
        <>
            <section className="packageSection">
                <Container>
                    <div className="sectionTItle">
                        <h2 className="">Packages</h2>
                        <strong>We provide versatile investment solutions that appeal to a variety of financial objectives and desires. Whether you're just getting started or want to maximize your profits, there's a solution for you. Both the Basic and Pro packages provide access to basic functionality as well as the ability to earn big returns through direct commissions and annual returns. Each plan is intended to help you achieve a wealthy future by providing attractive returns on your investment.

Take charge of your financial journey by selecting the package that suits your needs and get ready to invest for a rich and successful future. </strong>
                    </div>
                    {(!user || !user?.subscriptionType) ?
                        <Row className="justify-content-center">
                            <Col sm={6} lg={4}>
                                <div className="PlanWrapper">
                                   
                                    <h3>Basic</h3> 
                                    <h4>$50</h4>
                                    <a onClick={() => handleDeposit('basic')} className="btn themeBtn text-white w-100">Subscribe</a>
                                    <div className='packfeature'>
                                        <strong>Core feature including</strong>
                                        <ul>
                                            <li>Annual Return 100%-170%</li>
                                            <li>Up-to 3 Levels of Direct Commission</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>

                            <Col sm={6} lg={4}>
                                <div className="PlanWrapper">
                                    <h3>Pro</h3> 
                                    <h4>$100 </h4>
                                    <a onClick={() => handleDeposit('pro')} className="btn themeBtn text-white w-100">Subscribe</a>
                                    <div className='packfeature'>
                                        <strong>Core feature including</strong>
                                        <ul>
                                        <li>Annual Return 150%-210%</li>
                                            <li>Up-to 5 Levels of Direct Commission</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        : user?.subscriptionType == 'basic' ?
                            <Row className="justify-content-center">
                            <Col sm={6} lg={4}>
                                <div className="PlanWrapper activePack">
                                    <span className='activePackTag'>Active Package</span>
                                    <h3 style={{ color: "#ffbf41" }}>Basic</h3> 
                                    <h4>$50</h4>
                                    <a  className="btn themeBtn text-white w-100">Subscribed</a>
                                    <div className='packfeature'>
                                        <strong>Core feature including</strong>
                                        <ul>
                                            <li>Annual Return 100%-170%</li>
                                            <li>Up-to 3 Levels of Direct Commission</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>


                            </Row>
                            :
                            <Row className="justify-content-center">

                            <Col sm={6} lg={4}>
                            <div className="PlanWrapper activePack">
                            <span className='activePackTag'>Active Package</span>
                                    <h3 style={{ color: "#ffbf41" }}>Pro</h3> 
                                    <h4>$100 </h4>
                                    <a  className="btn themeBtn text-white w-100">Subscribed</a>
                                    <div className='packfeature'>
                                        <strong>Core feature including</strong>
                                        <ul>
                                            <li>Annual Return 150%-210%</li>
                                            <li>Up-to 5 Levels of Direct Commission</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>

                            </Row>
                    }


                </Container>
            </section>
        </>
    )
}
