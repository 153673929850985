import React from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Header } from "../component/Header";
import { useLocation, useNavigate } from "react-router-dom";
import helper from "../utils/helper";
import apiPath from "../utils/apiPath";
import Session from "../component/Session";
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const navigate = useNavigate()
  const { token } = useParams();


  const onSubmit = async(data) => {
    const res = await helper.api(apiPath.resetPassword+`/${token}`, 'post', {password:data?.confirmPassword})
    if(res?.success){
      Session.clearAllSession()
      navigate('/login')
    } else {
      console.log('api error');
    }
  };

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const togglePasswordVisibility = (passwordType) => {
    switch (passwordType) {
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="authPage">
        <div className="authContainer">
        <div  className="loginLogo"><img src="images/logo.png" alt="logo" /></div>
          <Form className="authForm" onSubmit={handleSubmit(onSubmit)}> 
            <Form.Group className="mb-4" controlId="formBasicNewPassword">
              <Form.Label>New Password</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New Password"
                  {...register("newPassword", {
                    required: "Please enter a new password.",
                    minLength: {
                      value: 6,
                      message: "Password should be at least 6 characters long.",
                    },
                  })}
                />
                <span onClick={() => togglePasswordVisibility("new")} className="password-toggle-icon">
                  {showNewPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                </span>
              </div>
              {errors.newPassword && <span className="error-message text-danger">{errors.newPassword.message}</span>}
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    required: "Please confirm your new password.",
                    validate: (value) => value === getValues("newPassword") || "Passwords do not match.",
                  })}
                />
                <span onClick={() => togglePasswordVisibility("confirm")} className="password-toggle-icon">
                  {showConfirmPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                </span>
              </div>
              {errors.confirmPassword && <span className="error-message text-danger">{errors.confirmPassword.message}</span>}
            </Form.Group>

            <Button type="submit" className="authAction themeBtn">Set Password</Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
