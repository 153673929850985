import React from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import helper from "../utils/helper";
import apiPath from "../utils/apiPath";
import Session from "../component/Session";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log("data", data)
    try {
      const res = await helper.api(apiPath.forgotPassword, 'post', { email: data.email });
      if (res?.success) {
        Session.clearAllSession();
        navigate('/login');
      } else {
        console.log('API error');
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <div className="authPage">
      <div className="authContainer">
        <a className="loginLogo"><img src="images/logo.png" alt="logo" /></a>
        <Form className="authForm" onSubmit={handleSubmit(onSubmit)}>

          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              {...register("email", {
                required: "Email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address."
                }
              })}
            />
            {errors.email && <span className="error-message text-danger">{errors.email.message}</span>}
          </Form.Group>

          <Button type="submit" className="authAction themeBtn">Send Reset Link</Button>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
