import { React, useState } from "react";
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, Table } from "react-bootstrap";
import { Header } from "../component/Header";
import { Footer } from "../component/Footer";
import { Banner } from "../component/Banner";
import { Price } from "../component/Price";
import { Reward } from "../component/Reward";
import { Package } from "../component/Package";
// import { Sidebar } from "../component/Sidebar";

const Home = () => {
 
  return (
    <>
       

        <Banner />
        <Price /> 
        <Reward />
        <Package />

       
 
    </>
  );
};
export default Home;
