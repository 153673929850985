import { React, useState } from "react";
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, Table, Accordion, FloatingLabel } from "react-bootstrap";
// import { Sidebar } from "../component/Sidebar";

const TermOfService = () => {

  return (
    <>

      <section className="contactPage py-4 py-lg-5">
        <Container>
          <div className="bg-white  p-4 p-md-5">

            <ol className="themeOrderList">
              <li>After investing in the project, you will receive your very first massive return within 18 months, and the rest of your income will be given to you as yearly returns(12 months).</li>
              <li>. In this project, the top 1000 investors will be shared in the company.</li>
              <li>We will make your queries solved within 12 to 24 hours. </li>
              <li>A 2% fee will be deducted on each withdrawal. </li>
              <li>There are no minimum or maximum limits for your withdrawal. You can withdraw the amount you want.</li>
              <li>After the subscription of the plan, you can't upgrade the plan.</li>
              <li>Our system will work only on "Bep-20 network".</li>
              <li>From a single mail, only one ID is allowed to sign up, and the mail ID should not be changed.</li>
              <li>There will be a server maintenance session twice a month.</li>
              <li>If you do not receive your return within 18 months and want to cash out your investment, then you'll receive 80% of your invested amount. If you
                want to receive a 100% return on your investment, you must wait 24 months from the date you invested. </li>
              <li>Sharing of the company will not be given to those users, who have applied for the refund of their investment.</li>
              <li> If you receive more returns than you invested, you will not be eligible for a refund. Only those who do not make any withdrawals will receive a refund. </li>
              <li>Zero fee will be charged on the refund.</li>
              <li>"Payment structure after 18 & 24 months for in between returns".</li>
              <Row>
                <Col lg={6}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>PHASE</th>
                        <th>NUMBER OF USER</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1<sup>st</sup>phase</td>
                        <td>1-1000</td>
                      </tr>

                      <tr>
                        <td>2<sup>nd</sup>phase</td>
                        <td>1001-3500</td>
                      </tr>

                      <tr>
                        <td>3<sup>rd</sup>phase</td>
                        <td>3501-7500</td>
                      </tr>

                      <tr>
                        <td>4<sup>th</sup>phase</td>
                        <td>7501-12500</td>
                      </tr>

                      <tr>
                        <td>5<sup>th</sup>phase</td>
                        <td>12501-16500</td>
                      </tr>

                      <tr>
                        <td>6<sup>th</sup>phase</td>
                        <td>16501-20000</td>
                      </tr>

                    </tbody>
                  </Table>
                </Col>

              </Row>

<li>Here is the share chart available for our forthcoming project.</li>
            </ol>

            <div>
              <Row>
                <Col lg={6}>
                  <div className="graphBanner"><img src="images/graph.png" alt="graph" /></div>
                </Col>

              </Row>

            </div>
          </div>
        </Container>
      </section> 

    </>
  );
};
export default TermOfService;
