import { React, useState } from "react";
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, Table, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { Sidebar } from "../component/Sidebar";

const Blog = () => {
  const navigate = useNavigate()

  return (
    <>
      <section className="aboutPage py-4 py-lg-5 ">
        <Container>
          <Row className="align-items-center">
            <Col md={6} lg={4}>
              <div className="blogGrid">
                <figure>
                  <span className="blogImg"><img src="images/blog1.jpg" alt="blog1" /></span>
                  <figcaption>
                    <span className="blogDate">10 Jun 2024</span>
                    <h3>Your Partner in Smart Investing.. </h3>
                    <p>Making wise decisions is essential to achieving financial success in the fast-paced world of
                      investing.</p>
                    <a onClick={() => navigate('/blog-detail', {state:1})} className="themeBtn btn text-white">Read More</a>
                  </figcaption>

                </figure>
              </div>
            </Col>

            <Col md={6} lg={4}>
              <div className="blogGrid">
                <figure>
                  <span className="blogImg"><img src="images/blog2.jpg" alt="blog1" /></span>
                  <figcaption>
                    <span className="blogDate">10 Jun 2024</span>
                    <h3>How to Start Your Journey in Crypto Investment..</h3>
                    <p>Do you have an interest in cryptocurrencies and are considering entering the intriguing..</p>
                    <a onClick={() => navigate('/blog-detail', {state:2})} className="themeBtn btn text-white">Read More</a>
                  </figcaption>

                </figure>
              </div>
            </Col>

            <Col md={6} lg={4}>
              <div className="blogGrid">
                <figure>
                  <span className="blogImg"><img src="images/blog2.jpg" alt="blog1" /></span>
                  <figcaption>
                    <span className="blogDate">10 Jun 2024</span>
                    <h3>Harnessing the Power of Blockchain: How Stellar Edge Capital.</h3>
                    <p>Harnessing the Power of Blockchain: How Stellar Edge Capital is Revolutionizing...</p>
                    <a onClick={() => navigate('/blog-detail', {state:3})} className="themeBtn btn text-white">Read More</a>
                  </figcaption>

                </figure>
              </div>
            </Col>

            <Col md={6} lg={4}>
              <div className="blogGrid">
                <figure>
                  <span className="blogImg"><img src="images/blog2.jpg" alt="blog1" /></span>
                  <figcaption>
                    <span className="blogDate">10 Jun 2024</span>
                    <h3>How to Build a Diversified Investment Portfolio in 2025.</h3>
                    <p>How to Build a Diversified Investment Portfolio in 2025...</p>
                    <a onClick={() => navigate('/blog-detail', {state:4})} className="themeBtn btn text-white">Read More</a>
                  </figcaption>

                </figure>
              </div>
            </Col>



             <Col md={6} lg={4}>
              <div className="blogGrid">
                <figure>
                  <span className="blogImg"><img src="images/blog2.jpg" alt="blog1" /></span>
                  <figcaption>
                    <span className="blogDate">10 Jun 2024</span>
                    <h3>The Future of NFTs: Investing in Digital Ownership and Art.</h3>
                    <p>The Future of NFTs: Investing in Digital Ownership and Art...</p>
                    <a onClick={() => navigate('/blog-detail', {state:5})} className="themeBtn btn text-white">Read More</a>
                  </figcaption>

                </figure>
              </div>
            </Col>



            <Col md={6} lg={4}>
              <div className="blogGrid">
                <figure>
                  <span className="blogImg"><img src="images/blog2.jpg" alt="blog1" /></span>
                  <figcaption>
                    <span className="blogDate">10 Jun 2024</span>
                    <h3>The Future of Private Equity: Key Trends for 2024 and Beyond.</h3>
                    <p>The Future of Private Equity: Key Trends for 2024 and Beyond...</p>
                    <a onClick={() => navigate('/blog-detail', {state:6})} className="themeBtn btn text-white">Read More</a>
                  </figcaption>

                </figure>
              </div>
            </Col>







            {/*<Col md={6} lg={4}>
                      <div className="blogGrid">
                          <figure>
                            <span className="blogImg"><img src="images/blog3.jpg" alt="blog1" /></span> 
                            <figcaption>
                            <span className="blogDate">10 Jun 2024</span>
                                <h3>We have recived good Achive this Year..</h3>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy    </p>
                                <a href="/blog-detail" className="themeBtn btn text-white">Read More</a>
                            </figcaption>

                          </figure> 
                    </div>
                  </Col>

                  <Col md={6} lg={4}>
                      <div className="blogGrid">
                          <figure>
                            <span className="blogImg"><img src="images/blog4.jpg" alt="blog1" /></span> 
                            <figcaption>
                            <span className="blogDate">10 Jun 2024</span>
                                <h3>We have recived good Achive this Year..</h3>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy    </p>
                                <a href="/blog-detail" className="themeBtn btn text-white">Read More</a>
                            </figcaption>

                          </figure> 
                    </div>
                  </Col>

                  <Col md={6} lg={4}>
                      <div className="blogGrid">
                          <figure>
                            <span className="blogImg"><img src="images/blog5.jpg" alt="blog1" /></span> 
                            <figcaption>
                            <span className="blogDate">10 Jun 2024</span>
                                <h3>We have recived good Achive this Year..</h3>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy    </p>
                                <a href="/blog-detail" className="themeBtn btn text-white">Read More</a>
                            </figcaption>

                          </figure> 
                    </div>
                  </Col>

                  <Col md={6} lg={4}>
                      <div className="blogGrid">
                          <figure>
                            <span className="blogImg"><img src="images/blog1.jpg" alt="blog1" /></span> 
                            <figcaption>
                            <span className="blogDate">10 Jun 2024</span>
                                <h3>We have recived good Achive this Year..</h3>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy    </p>
                                <a href="/blog-detail" className="themeBtn btn text-white">Read More</a>
                            </figcaption>

                          </figure> 
                    </div>
                  </Col>

                 <Col md={6} lg={4}>
                      <div className="blogGrid">
                          <figure>
                            <span className="blogImg"><img src="images/blog2.jpg" alt="blog1" /></span> 
                            <figcaption>
                            <span className="blogDate">10 Jun 2024</span>
                                <h3>We have recived good Achive this Year..</h3>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy    </p>
                                <a href="/blog-detail" className="themeBtn btn text-white">Read More</a>
                            </figcaption>

                          </figure> 
                    </div>
                  </Col>

                 <Col md={6} lg={4}>
                      <div className="blogGrid">
                          <figure>
                            <span className="blogImg"><img src="images/blog3.jpg" alt="blog1" /></span> 
                            <figcaption>
                            <span className="blogDate">10 Jun 2024</span>
                                <h3>We have recived good Achive this Year..</h3>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy    </p>
                                <a href="/blog-detail" className="themeBtn btn text-white">Read More</a>
                            </figcaption>

                          </figure> 
                    </div>
                  </Col>

                 <Col md={6} lg={4}>
                      <div className="blogGrid">
                          <figure>
                            <span className="blogImg"><img src="images/blog4.jpg" alt="blog1" /></span> 
                            <figcaption>
                            <span className="blogDate">10 Jun 2024</span>
                                <h3>We have recived good Achive this Year..</h3>
                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                                    Lorem Ipsum has been the industry's standard dummy    </p>
                                <a href="/blog-detail" className="themeBtn btn text-white">Read More</a>
                            </figcaption>

                          </figure> 
                    </div>
                  </Col> */}


          </Row>
        </Container>
      </section>



    </>
  );
};
export default Blog;
