import React from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'

export const Price = () => {
  return (
    <>
        <section className="crytoPrice">
            <Container>
               <div className="sectionTItle">
                    <h2>Just level Income</h2>
<p>This strategy allows you to make direct commissions by utilizing your network and team building, depending on the niche you choose to operate in. The method is structured such that your revenues increase incrementally as you establish and strengthen your team. This tiered structure allows you to gain not only from your own direct efforts, but also from those of your team members, increasing your earning potential.

Below is a breakdown of the level chart, which shows the percentage of money you may expect to earn dependent on the tier or ""level"" you accomplish inside the system. As your team grows at various levels, your revenues will increase dramatically, giving you both financial flexibility and stability.

Level Chart and Earnings Breakdown</p>
               </div>

               <div className="themeDataTable"> 
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>level</th>
                        <th>Income %</th>
                        <th>Team</th>
                        <th>Basic</th>
                        <th>Pro</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><strong>level 1</strong></td>
                        <td>35%</td>
                        <td>3</td>
                        <td>52.5</td>
                        <td>105</td>
                      </tr>

                      <tr>
                        <td><strong>level 2</strong></td>
                        <td>6%</td>
                        <td>9</td>
                        <td>27</td>
                        <td>54</td>
                      </tr>

                      <tr>
                        <td><strong>level 3</strong></td>
                        <td>4%</td>
                        <td>27</td>
                        <td>54</td>
                        <td>108</td>
                      </tr>

                      <tr>
                        <td><strong>level 4</strong></td>
                        <td>3%</td>
                        <td>81</td>
                        <td className="text-red">NIL</td>
                        <td>243</td>
                      </tr>

                      <tr>
                        <td><strong>level 5</strong></td>
                        <td>2%</td>
                        <td>243</td>
                        <td className="text-red">NIL</td>
                        <td>486</td>
                      </tr> 
                    </tbody>
                    
                    <tfoot> 
                        <tr>
                          <td colSpan="3" className="text-center tebleFoot"><strong>Total Earning</strong></td> 
                          <td className="tebleFoot"><strong>133.5 $</strong></td>
                          <td className="tebleFoot"><strong>996 $</strong></td>
                        </tr> 
                      </tfoot>
                  </Table>  
               </div>




            </Container> 
        </section>
    </>
  )
}
