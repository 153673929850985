import { React, useState } from "react";
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, Table, Accordion, FloatingLabel } from "react-bootstrap"; 
// import { Sidebar } from "../component/Sidebar";

const PrivacyPolice = () => {
 
  return (
    <> 
 
    <section className="contactPage py-4 py-lg-5">
      <Container> 
       <div className="bg-white  p-4">
          <h2 className="AsTitle mb-3 mb-md-4">Privacy Policy</h2>

              <hgroup className="mb-3 mb-md-4 themeArticle">
                <h3> There is a deduction of 2% on every withdrawal. </h3>
              </hgroup>

              <hgroup className="mb-3 mb-md-4 themeArticle">
                <h3>There is no limit for your withdrawals, you can withdraw the amount you want as per your wallet.
                </h3>

              </hgroup>


              <hgroup className="mb-3 mb-md-4 themeArticle">
                <h3>Basic plan leaders are eligible to receive their commission up to level 3.</h3>
              </hgroup>

              <hgroup className="mb-3 mb-md-4 themeArticle">
                <h3>Pro plan leaders are eligible to receive their commission up to level 5.</h3>
              </hgroup>

              <hgroup className="mb-3 mb-md-4 themeArticle">
                <h3>In the project, there is only one time investment plan from a single ID.</h3>
              </hgroup>



          </div>

      </Container>
    </section>

 


 
    
 
    </>
  );
};
export default PrivacyPolice;
