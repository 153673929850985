import React, { useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useToastContext from "../hooks/useToastContext";
import apiPath from "../utils/apiPath";
import helper from "../utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import Session from "../component/Session";
import { PageLoader } from "../component/PageLoader";

const Deposit = () => {
  const { register, handleSubmit, formState: { errors },reset } = useForm();

  const { state } = useLocation()
  const [image, setImage] = useState(null);
  const [isLoader, setIsLoader] = useState(null);
const navigate = useNavigate()
  const [paymentOTPID, setPaymentOTPID] = useState(null);


  const [profilePic, setProfilePic] = useState(null);
  const [profilePicError, setProfilePicError] = useState("Payment screenshot is required.");;

  const handleCopyClick = () => {
    const textArea = document.createElement('textarea');
    textArea.value = `0x87c3A242d263Fc01f1339EFb8585573766cd4Ef2`;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
      window.alert("Wallet address copied successfully.");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }

    document.body.removeChild(textArea);
  };

  const onSubmit = async (data) => {

    setIsLoader(true)
    const { trxNo, otp, name } = data

    if (profilePicError) {
      return; // Do not submit if there is a file validation error
    }
    let payload = new FormData()
    payload.append("receipt", profilePic)
    payload.append("subscriptionType", state)
    payload.append("name", name)
    payload.append("trxNo", trxNo)
    // payload.append("otp", otp)
    // payload.append("otpId", paymentOTPID)


    try {
      const res = await helper.api(apiPath.addPayment, 'post', payload)

      if (res?.success) {
        setIsLoader(false)
        window.alert(res?.message);
        reset();
        navigate('/')
      } else {
        setIsLoader(false)
        window.alert(res?.message);
        return false
      }
    } catch (error) {
      console.log("error", error.message);
      setIsLoader(false)
    }


    console.log(data);
    // Handle form submission here
  };




  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setProfilePic(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    // Custom validation
    if (file) {
      if (file.size > 5000000) { // File size greater than 5MB
        setProfilePicError("File size must be less than 5MB");
      } else if (!["image/jpeg", "image/png"].includes(file.type)) { // Allowed file types
        setProfilePicError("Only JPEG and PNG files are allowed");
      } else {
        setProfilePicError(""); // Clear error if file is valid
      }
    } else {
      setProfilePicError("Payment screenshot is required.");
    }
  };

  const getPaymentOTP = async () => {
    try {
      const res = await helper.api(apiPath.sendPaymentOTP, 'get', {})

      if (res?.success) {
        window.alert(res?.message);
        setPaymentOTPID(res?.results?.OTPID)
      } else {
        window.alert(res?.message);
        return false
      }
    } catch (error) {
      console.log("error", error.message);
    }

  }

  return (
    <>
      <section className="contactPage py-4 py-lg-5">
        <Container>
          <div className="Diposite-Wrapper">
            <div className="bg-white p-4 box-shaddow">
              <div className="contact_us mt-4">
                <Row>
                  <Col md={5}>
                    <div className="QR-code">
                      <img src="images/qr-address.jpg" alt="qr-code" />
                    </div>

                    <div className="Copy-Referral mb-4 pointer mt-3">
                      <p onClick={handleCopyClick}>
                        <img src="images/copyicon.png" alt="copyIcon-copy" />
                        0x87c3A242d263Fc01f1339EFb8585573766cd4Ef2
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form onSubmit={handleSubmit(onSubmit)} className="ps-0 ps-md-3">
                      <Form.Group className="mb-4" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter name"
                          {...register("name", { required: "Name is required" })}
                        />
                        {errors.name && <p className="text-danger mb-0 mt-1">{errors.name.message}</p>}
                      </Form.Group>

                      <Form.Group className="mb-0" controlId="formTrxNo">
                        <Form.Label>Trx. No.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter transaction number"
                          {...register("trxNo")}
                        />
                      </Form.Group>

                      {/* <div className="py-3">
                      <button onClick={() => getPaymentOTP()} type="button" className="themelink border-0 bg-transparent text-blue fw-bold px-0">Send OTP</button>
                      </div>

                      <Form.Group className="mb-4" controlId="formOtp">
                        <Form.Label>OTP</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter OTP"
                          {...register("otp", { required: "OTP is required" })}
                        />
                        {errors.otp && <p className="text-danger mb-0 mt-1 ml-105">{errors.otp.message}</p>}
                      </Form.Group> */}

                      <div className="ProfilePic mb-4 dipositeProfile">
                          <div className="d-flex deposite-Pic">
                          <figure>
                          <span className="picFrem">
                            <img src="images/loginedUser.png" className="d-none" alt="profile" />
                          </span>
                          <span className="uploadShape">
                            <small className="text-center">
                              <img src="images/upload-icon.png" alt="profile" />
                              <br />Select File
                            </small>
                            <input
                              type="file"
                              onChange={handleFileChange}
                            />
                          </span> 
                        </figure>
                       {image&& <spna className="UserUploaded"><img src= { image} alt="User" /></spna>}
                          </div>
                        {profilePicError && <p className="ml-105 text-danger mt-2 mb-0">{profilePicError}</p>}
                      </div>

                      <button type="submit" className="themeBtn btn btn-primary px-4">Submit</button>
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
        {isLoader&&<PageLoader/>}
      </section>
    </>
  );
};

export default Deposit;
