import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'

export const Banner = () => {
  return (
    <>
       <section className="website_banner">
          <Container>
              <Row className="align-items-center">
                <Col lg={6}>
                  <div className="bannerCaption">
                      <h1 className="fw-bold text-blue">Welcome To the Best Commission Platform</h1>
<p>Discover Stellar Edge Capital, where we are committed to revolutionizing the investing landscape through new solutions and strategic knowledge. Our platform provides a full spectrum of services tailored to both individual investors and businesses looking to maximize their financial portfolios. Utilize our tools and market knowledge to make smart financial decisions and reach your goals. Our skilled professionals offer customized strategies based on your needs. At Stellar Edge Capital, we prioritize your success by combining cutting-edge technology with a deep understanding of market dynamics. Visit us at Stellar Edge Capital to explore how we can help you unlock your investment potential and drive growth in today’s dynamic financial environment.</p>
                    <a href='/contact-us' className="btn text-white themeBtn px-4">Contact Us</a>
                  </div>
                </Col>  

                <Col lg={6}>
                    <figure><img src="images/banner1.svg" className='w-100' alt="banner" /></figure>
                </Col>
                
              </Row> 
          </Container>
          <span className="over-style1"><img src="images/over_style3.png" alt="" /></span>
          <span className="over-style2"><img src="images/over_style3.png" alt=""/></span>
          <span className="over-style3"><img src="images/over_style3.png" alt=""/></span>
          <span className="over-style4"><img src="images/over_style.png" alt="" /></span>
          <span className="over-style5"><img src="images/over_style2.png" alt=""/></span>
          <span className="over-style6"><img src="images/over_style3.png" alt=""/></span>
       </section>
    </>
  )
}
