import React, { useEffect } from 'react'
import { Container, Dropdown, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import Session from './Session'
import { useNavigate } from 'react-router-dom'
import helper from '../utils/helper'
import apiPath from '../utils/apiPath'
import { PageLoader } from './PageLoader'

export const Header = () => {
  const navigate = useNavigate()
  const isLoader = Session.getSession('loader')
  const handleLogout = async () => {
    const res = await helper.api(apiPath.logout, 'get', {})
    if (res?.success) {
      Session.clearAllSession()
      navigate('/login')
    }
  }
  const user = Session.getSession('user')

  const commonRoutes = ['/reset-password', '/about', 'contact-us']

  useEffect(() => {
    if (!user && commonRoutes.includes(window.location.pathname.startsWith)) {
      navigate(`/login${window.location.search}`)
    }
    if (user && window.location.pathname === '/login') {
      navigate('/')
    }
  }, [user, isLoader])

  const handleProfile = () => {
    navigate('/profile')
  }

  const handleChangePassword = () => {
    navigate('/change-password')
  }



  return (
    <>
      <div className='WebHeader'>

        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href='/' onClick={() => navigate('/')}><img src="images/logo.png" alt='logo' /></Navbar.Brand>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link onClick={() => navigate('/')}>Home</Nav.Link>
                <Nav.Link onClick={() => navigate('/about')}>About us</Nav.Link>
                <Nav.Link onClick={() => navigate('/investment-plan')} >Investment Plan </Nav.Link>
                <Nav.Link onClick={() => user && navigate('/my-team')}>Team Plan</Nav.Link>
                <Nav.Link onClick={() => navigate('/contact-us')}>Contact Us</Nav.Link>
                {!user && <Nav.Link onClick={() => navigate('/login')}>Login</Nav.Link>}


              </Nav>
            </Navbar.Collapse>
            <div className='d-flex align-items-center'>
              {user &&
                <div className='headerRight'>
                  <Dropdown>
                    <Dropdown.Toggle className='bg-transparent border-0 p-0 d-flex align-items-center text-blue' id="dropdown-basic">
                      <span className='loginedUser'><img src={user?.profilePic} alt='login' /></span><strong className='align-middle d-sm-inline-block px-2'>{user?.name}</strong>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='p-0'>
                      <Dropdown.Item className="py-2 fw-medium" onClick={handleProfile}>Profile</Dropdown.Item>
                      <Dropdown.Item className="py-2 fw-medium" onClick={handleChangePassword}>Change Password</Dropdown.Item>

                      <Dropdown.Item className="py-2 fw-medium" onClick={handleLogout}>Log Out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </div>}
              <Navbar.Toggle aria-controls="basic-navbar-nav" className='ms-2' />
            </div>
          </Container>
        </Navbar>


      </div>


    </>
  )
}
