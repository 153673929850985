import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, FloatingLabel } from 'react-bootstrap';
import axios from 'axios'; // For making API calls
import helper from '../utils/helper';
import apiPath from '../utils/apiPath';

const Contact_Us = () => {
  const { control, handleSubmit,reset, register, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      const res = await helper.api(apiPath.addSupport, 'post', data)
      if(res?.success){
        alert(res?.message);
        reset();
      }
    } catch (error) {
      alert('There was an error submitting the form.');
    }
  };

  return (
    <section className="contactPage py-4 py-lg-5">
      <Container>
        <div className="bg-white p-4">
          <div className="contact_us">
            <Row>
              <Col lg={6} className="border-lg-end border-0">
                <div className="p-lg-5 p-0">
                  <h4 className="fw-500 text-dark mb-3">Get in Touch</h4>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: 'Name is required' }}
                      render={({ field }) => (
                        <FloatingLabel controlId="floatingName" label="Name*" className="w-100 p-0 text-blue">
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            className="contact_num mb-3"
                            {...field}
                          />
                        </FloatingLabel>
                      )}
                    />
                    {errors.name && <p className="text-danger">{errors.name.message}</p>}

                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'Email is required', pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Invalid email format' } }}
                      render={({ field }) => (
                        <FloatingLabel controlId="floatingEmail" label="Email*" className="w-100 p-0 text-blue">
                          <Form.Control
                            type="email"
                            placeholder="Email address"
                            className="contact_num mb-3"
                            {...field}
                          />
                        </FloatingLabel>
                      )}
                    />
                    {errors.email && <p className="text-danger">{errors.email.message}</p>}

                    <div className="d-flex contact_num align-items-center text-blue mb-4">
                      <Form.Select
                        aria-label="Default select example"
                        className="w-25 border-0"
                        {...register("countryCode")}
                      >
                        <option value="+91">+91</option>
                      </Form.Select>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{ required: 'Contact No is required' }}
                        render={({ field }) => (
                          <FloatingLabel controlId="floatingContactNo" label="Contact No*" className="w-100 p-0">
                            <Form.Control
                              type="text"
                              placeholder="Contact No"
                              className="border-0"
                              {...field}
                            />
                          </FloatingLabel>
                        )}
                      />
                    </div>
                    {errors.phoneNumber && <p className="text-danger">{errors.phoneNumber.message}</p>}

                    <Controller
                      name="description"
                      control={control}
                      rules={{ required: 'Message is required' }}
                      render={({ field }) => (
                        <FloatingLabel controlId="floatingMessage" label="Message*" className="w-100 p-0 text-blue mb-3">
                          <Form.Control
                            as="textarea"
                            style={{ height: "100px" }}
                            placeholder="Your message"
                            className="contact_num mb-3"
                            {...field}
                          />
                        </FloatingLabel>
                      )}
                    />
                    {errors.description && <p className="text-danger">{errors.description.message}</p>}

                    <Button type="submit" className="themeBtn w-100">
                      Submit
                    </Button>
                  </Form>
                </div>
              </Col>
              <Col lg={6} className="contact_border">
                <div className="p-lg-5 p-0 pt-lg-5 pt-2">
                  <h4 className="fw-500 text-dark mb-3 fw-bold">Contact Us</h4>
                  <p className="mb-1 text-dark">
                    Any questions at all, get in touch.
                  </p>
                  <p className="mb-1 text-dark">
                    We’re here to assist in any way we can, so feel free to
                    drop us an email at stellaredgecapital2024@gmail.com - we will get back
                    to you very quickly!
                  </p>
                  <div className="d-flex fw-300 text-dark align-items-center pb-3 pt-2">
                    <img
                      src="images/tel_icon.png"
                      alt="tel icon"
                      className="me-2"
                    />
                    {/* <span className=""> Tel: +357 77778088</span> */}
                  </div>
                  <div className="d-flex fw-300 text-dark align-items-center pb-3 pt-2">
                    <img
                      src="images/email_icon.png"
                      alt="email icon"
                      className="me-2"
                    />
                    <span className="">stellaredgecapital2024@gmail.com</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact_Us;
