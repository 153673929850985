import { React, useEffect, useState } from "react";
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, Table, Accordion } from "react-bootstrap"; 
import apiPath from "../utils/apiPath";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import UserTable from "./UserTable";
import ReactPaginate from "react-paginate";
import helper from "../utils/helper";
import { PageLoader } from "../component/PageLoader";
// import { Sidebar } from "../component/Sidebar";

const MyTeam = () => {

  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isLoader, setIsLoader] = useState(null);
  const queryStatus = queryParams.get("status");
  const [pageSize] = useState(10);
  const [users, setAllUser] = useState([]);
  const [usersDetails, setUsersDetails] = useState({});

  const [page, setPage] = useState(1);
  const [item, setItem] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
const navigate = useNavigate()
  const [isInitialized, setIsInitialized] = useState(false);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);

  const userId = queryParams.get('id');
  const [filterData, setFilterData] = useState({
    category: queryStatus ? queryStatus : "",
    searchkey: "",
    startDate: "",
    endDate: "",
    isReset: 0,
    isFilter: false,
  });

  const [sort, setSort] = useState({
    sortKey: "createdAt",
    sortType: "desc",
  });
  const [csv, setCsv] = useState("");

  const handleResultProcessing = (data) => {
    const response = data?.results;
    const resultStatus = data?.success;
    setAllUser(response?.docs);
    setCsv(response?.csv);
    if (resultStatus) {
      setPaginationObj({
        ...paginationObj,
        page: resultStatus ? response.page : null,
        pageCount: resultStatus ? response.totalPages : null,
        perPageItem: resultStatus ? response?.docs.length : null,
        totalItems: resultStatus ? response.totalDocs : null,
      });
    }
  };

  const handlePageUpdate = () => {
    if (users?.length > 1) {
      setPage(page - 1);
      setIsDelete(true);
      setPaginationObj({ ...paginationObj, page: page - 1 });
    }
  };


  const handlePageClick = (event) => {
    const newPage = event.selected + 1;
    setPage(newPage);
  };

  const handelEdit = (items) => {
    setItem(items);
    setEditShowModal(true);
  };

  const handleDateChange = (start, end) => {
    setPage(1);
    setFilterData({
      ...filterData,
      startDate: start,
      endDate: end,
      isFilter: true,
    });
    setIsDelete(true);
  };
  const statusPage = (e) => {
    setFilterData({ ...filterData, category: e.target.value, isFilter: true });
    setPage(1);
    setIsDelete(true);
  };

  const getAllUsers = async (data) => {
    setIsLoader(true)
    const { category, startDate, endDate, searchkey, isFilter } = filterData;
    const shouldUpdate =
      (data?.deletePage && users?.length > 1) ||
      (isFilter && category && data?.statusChange && users?.length > 1);
    if (shouldUpdate) {
      handlePageUpdate();
    } else {
      setIsDelete(false);
    }
    const payload = {
      page,
      pageSize,
      status: category,
      keyword: searchkey,
      sortKey: sort.sortKey,
      sortType: sort.sortType,
      userId:selectedUserId ||''
    };
    const params = new URLSearchParams(payload).toString();
    try {
      const res = await helper.api(`${apiPath.userList}?${params}`, 'get',{});
      if (res?.success) {
        setIsLoader(false)
        setAllUser(res?.results?.docs||[])
        setUsersDetails(res?.results)
       console.log('res', res);
      } else {
        setIsLoader(false)
        console.log(res?.message)
      }
    } catch (error) {
      setIsLoader(false)
      console.log('error in login api::', error);
    }
    
  };
  const handleReset = () => {
    setFilterData({
      category: "",
      searchkey: "",
      startDate: "",
      endDate: "",
      isReset: filterData?.isReset + 1,
      isFilter: false,
    });
    setPage(1);
    setIsDelete(true);
    setSearchTerm("");
  };


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);


  useEffect(() => {
    // setSelectedUserId(userId)
    getAllUsers();
    if(selectedUserId){
      navigate(`/my-team?id=${selectedUserId}`)
    }
  }, [page, filterData, sort, selectedUserId]);


  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    } else if (searchTerm || !filterData?.isReset) {
      setFilterData({
        ...filterData,
        isReset: 0,
        searchkey: debouncedSearchTerm || "",
        isFilter: !!debouncedSearchTerm,
      });
      setPage(1);
    }
  }, [debouncedSearchTerm]);

 
  return (
    <> 
          <div className="bg-[#F9F9F9]">
        <div className="px-3 py-4">
          <div className="bg-white border border-[#E9EDF9] rounded-lg">
            <div className="border-b border-b-[#E3E3E3] grid  xl:grid-cols-2 lg:grid lg:grid-cols-1 gap-2 px-4 py-3">
              <div className="col-span-2 flex flex-wrap  items-center w-full">
                <div className="flex  justify-between lg:pt-0 pt-3 flex-wrap mb-2 w-full">
                <div className="tableFilterHead mb-3">
              <Row>
                <Col lg={6}>
                    <div className="d-flex align-items-center">
                      <Button className="btn themeBtn px-4 me-2" onClick={()=>navigate(-1)}><img src="images/backArrow.png" style={{maxHeight:"16px"}} alt="back" /></Button>
                      <div className="SearchSelect position-relative">
                        <Form.Control placeholder="Search.." />
                        <button className="SearchFilter">
                          <img src="images/searchIcon.png" alt="search" />
                        </button>
                      </div>
                    </div>
                </Col>

                <Col lg={6}>
                  <div className="ms-auto tableSelectRight">
                  
                    <Form.Select aria-label="Default select example">
                      <option>All Select</option>
                      <option value="1">Active</option>
                      <option value="2">InActive</option>
                    </Form.Select>

                    <div className="SelectAction">
                      <Button className="btn themeBtn px-4 me-2">Reset</Button>
                     
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
                </div>
              </div>
            </div>
            <UserTable
            setSelectedUserId={setSelectedUserId}
              users={users}
              // user={user}
              getAllUsers={getAllUsers}
              handelEdit={handelEdit}
              page={page}
            />
              <div className='d-flex justify-content-between w-100 p-3 p-md-4 align-items-center'>
             {usersDetails?.totalDocs&& <p className='m-0'>Total {usersDetails?.totalDocs} Records</p>}
              <nav aria-label='Page navigation example' className="themePagger">
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='Next'
                  // onPageChange={handlePageClick}
                  pageRangeDisplayed={true}
                  pageCount={usersDetails?.totalPages}
                  previousLabel='Previous'
                  renderOnZeroPageCount='null'
                  className=''
                  activeClassName='pagination-active'
                  previousLinkClassName=''
                  nextLinkClassName=''
                  pageLinkClassName=''
                  activeLinkClassName='pagination-active'
                  forcePage={1}
                />
              </nav>
            </div>
          </div>
        </div>
        {isLoader&&<PageLoader/>}
      </div>
 
    </>
  );
};
export default MyTeam;