import { React, useState } from "react";
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, Table, Accordion } from "react-bootstrap";
// import { Sidebar } from "../component/Sidebar";

const About = () => {

  return (
    <>
      <section className="aboutPage py-4 py-lg-5 ">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="sectionCaption">
                <h2 className="AsTitle">About US</h2>
                <p>Thousands of families in India used to experience different financial crises due to their meager expenses. As a helping hand on your shoulders, here we are.

                  We are setting up a family, by which we can help you by giving the higher returns on your small investments. We are using advanced technology to make things happen for every one of us.

                  With your help, we are establishing great projects of the decade. So, why being late? Start investing in your future, Invest in Stellar Edge Capital, where your capital and our commitment combine to form a huge flow of success.</p>
              </div>
            </Col>

            <Col md={6}>
              <div className="AboutPIC">
                <img src="images/about.jpg" className="img-thumbnail" alt="about" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>



      <section className="MissionSection py-4 py-lg-5 bg-white">
        <Container>
          <Row className="align-items-center ">
            <Col md={6}>
              <div className="AboutPIC pe-md-4">
                <img src="images/mission.jpg" className="img-thumbnail" alt="about" />
              </div>
            </Col>

            <Col md={6}>
              <div className="sectionCaption">
                <h2 className="AsTitle">Mission</h2>
                <p>Our goal for this year is to reach Stellar Edge Capital's valuation of 105cr, through which we can further our own development and ensure that you are all taken care of.
                  Our goal is to make sure you have high returns in your pocket as easily as possible while offering you every assistance during your times of need.</p>

              </div>
            </Col>

          </Row>
        </Container>
      </section>



      <section className="GAQSection py-4 py-lg-5">
        <Container>
          <div className="sectionTItle">
            <h2 className="">Frequently Asked Questions </h2>
            <p>Here are some generally asked queries:</p>           </div>

          <Accordion defaultActiveKey="0" className="ThemeAccordian">
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is Stellar Edge Capital (SEC)?</Accordion.Header>
              <Accordion.Body>
              Stellar edge Capital is a recognized name in the financial services world, focusing on wealth planning, financial advice, and investment management. We create tailored, data-driven strategies to help individuals, businesses, and institutions reach their financial objectives. Our skilled financial advisors are dedicated to providing expert guidance on risk management, retirement planning, and managing investment portfolios. By combining personalized investment approaches with cutting-edge market analysis, Stellar edge Capital aims to reduce risk and enhance returns. For us, achieving long-term financial success and ensuring client satisfaction are top priorities.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Are SEC investments a good idea?</Accordion.Header>
              <Accordion.Body>
              Collaborating with Stellar Edge Capital is an astute decision for those aiming to enhance their wealth and achieve remarkable annual returns. Our extensive knowledge in investment management, combined with cutting-edge strategies in emerging fields such as e-commerce and Web-3 gaming, enables us to create a diversified portfolio aligned with your financial objectives. By partnering with us, you can benefit from substantial profits as well as direct commissions linked to the performance of your investment team. With Stellaredge Capital's established business practices, you will have a reliable pathway to sustained financial success.           </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>How will the SEC provide us high returns & direct commission?</Accordion.Header>
              <Accordion.Body>
              Stellar edge Capital utilizes cutting-edge technology across various platforms such as blockchain banking, e-commerce, and Web-3 gaming to achieve remarkable investment returns. We boost profitability by effectively managing resources and drawing in capital from distinguished investors through a range of investment portfolios. Our skilled team diligently monitors market trends to optimize your investment potential, ensure direct commissions, and offer ongoing growth opportunities. With Stellar edge Capital's forward-thinking approaches in wealth management and investment, clients can anticipate enduring financial success and significant returns.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Is the SEC a credible organization?</Accordion.Header>
              <Accordion.Body>
              In summary, Stellar Edge Capital stands out as a highly reputable and trustworthy provider of financial services. We prioritize the security and safety of your assets by adhering to rigorous financial standards and maintaining full transparency in our operations. Our expertise in wealth planning and investment management is extensive. Our dedicated team is focused on maximizing returns through strategic planning and advanced platforms. With a proven track record and a client-centric approach, investment solutions from Stellar Edge Capital are guaranteed to be reliable, secure, and profitable.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>How does one go about making an investment in the SEC? </Accordion.Header>
              <Accordion.Body>
              Getting started with Stellar Edge Capital is super easy and can help you hit your financial goals. First, schedule a meeting with one of our expert financial advisors who will take a look at your risk tolerance and investment aims. Then, we’ll work together to find the perfect investment portfolio for you, whether that’s in e-commerce, Web-3 gaming, or other up-and-coming sectors. Once your investment is up and running, we’ll manage it actively to ensure you see solid returns and growth over time.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>What sort of security is the SEC providing for us?</Accordion.Header>
              <Accordion.Body>
              Stellar Edge Capital values security and operates on the secure BEP-20 blockchain. Using Web-3 Wallets, we ensure transparency and safety in managing funds. Our centralized system protects investments from fraud and unauthorized access. The BEP-20 network uses smart contracts and encryption to minimize risks. Our monitoring tools detect and address security vulnerabilities in real time. We constantly improve security measures to stay ahead of threats in the financial industry. Stellar Edge Capital aims to maximize earnings while ensuring top-tier financial security with blockchain technology.
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="6">
              <Accordion.Header>How can I get a sign-up referral link?</Accordion.Header>
              <Accordion.Body>
              To obtain your Stellaredge Capital sign-up referral link, please log into your account and navigate to the Referral Program section on our website. From there, you can generate a customized referral link to share with your friends and contacts. Additionally, we have streamlined the process of recommending others via popular social media platforms such as Twitter, Facebook, and LinkedIn. You can either send invites directly from your account or share those links. And the best part? For every successful sign-up that uses your link, you'll earn money! It's a fantastic way to help others grow their investment portfolios while making some extra cash for yourself.
                    </Accordion.Body>
            </Accordion.Item>
          </Accordion>


        </Container>

      </section>


    </>
  );
};
export default About;
