import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios'; // For making API calls
import helper from '../utils/helper';
import apiPath from '../utils/apiPath';
import Session from '../component/Session';
import { PageLoader } from '../component/PageLoader';

const Withdrawal = () => {
  const [profile, setProfile] = useState(null);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [isLoader, setIsLoader] = useState(null);
  const getProfile = async () => {
    try {
      const res = await helper.api(apiPath.profile, 'get', {});
      if (res?.success) {
        setProfile(res?.results);
      } else {
        window.alert(res?.message);
        return false;
      }
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const onSubmit = async (data) => {
    if (data?.amount > profile?.wallet) {
      window.alert('You can not withdrawal more then you have.')
      return
    }
    setIsLoader(true)
    try {

      const payload = new FormData()
      payload.append('amount', data?.amount)
      payload.append('walletAddress', data?.walletAddress)
      payload.append('transactionType', 'withdrawal')
      payload.append('otpId', 'withdrawal')
      payload.append('otp', 123456)



      const response = await helper.api(apiPath.addPayment, 'post', payload);
      if (response.success) {
        // Handle success, maybe show a success message or update the UI
          setIsLoader(false)
        window.alert('Withdrawal request raised successfully.');
        reset(); // Reset form fields after successful submission
      } else {
        // Handle error response from the API
          setIsLoader(false)
        window.alert(response.message || 'An error occurred');
      }
    } catch (error) {
      console.log('Error:', error.message);
        setIsLoader(false)
      window.alert('An error occurred while making the request');
    }
  };

  return (
    <section className="contactPage py-4 py-lg-5">
      <Container>
        <div className='available-balance-Wrapper'>
          <strong className='availableAmount'>${profile?.wallet || 0}</strong>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="formWalletAddress">
              <Form.Label>Wallet Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                {...register('walletAddress', { required: 'Wallet address is required' })}
              />
              {errors.walletAddress && <p className="text-danger">{errors.walletAddress.message}</p>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Amount"
                {...register('amount', {
                  required: 'Amount is required',
                  min: { value: 0.01, message: 'Amount must be greater than 0' }
                })}
              />
              {errors.amount && <p className="text-danger">{errors.amount.message}</p>}
            </Form.Group>

            <Button type="submit" className="themeBtn btn btn-primary px-4">Submit</Button>
          </Form>
        </div>
      </Container>
      {isLoader&&<PageLoader/>}
    </section>
  );
};

export default Withdrawal;
