import React, { useState } from "react";
import { Button, Form, FormCheck } from "react-bootstrap";
import { GoogleLogin } from '@react-oauth/google';
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import helper from "../utils/helper";
import apiPath from "../utils/apiPath";
import Session from "../component/Session";
import useToastContext from "../hooks/useToastContext";
import { PageLoader } from "../component/PageLoader";


const Login = () => {
  const navigate = useNavigate();
  const { control, handleSubmit, setError, formState: { errors } } = useForm();
  const [rememberMe, setRememberMe] = useState(false);
  const [check, setCheck] = useState(false);
  const [isLoader, setIsLoader] = useState(null);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const location = useLocation();
  const notification = useToastContext();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const handleLogin = async (data) => {
    try {
      let payload = {
        email: data.email,
        password: data.password
      }
     
      const res = await helper.api(apiPath.login, 'post', payload);

      if (res?.success) {
        window.alert(res?.message);
        Session.setSession("token", res?.results?.token);
        Session.setSession("user", res?.results);
        navigate('/');
      } else {
        window.alert(res?.message);
      }
    } catch (error) {
      console.log('error in login api::', error);
    }
  };

  const handleLoginSuccess = async (response) => {
    // Handle successful social login
 setIsLoader(true)
    try {
      let payload = { accessToken: response?.credential }
      if(code){
        payload.code=code
      }
      const res = await helper.api(apiPath.socialLogin, 'post', payload);
      if (res?.success) {
       setIsLoader(false)
        // window.alert(res?.message);
        Session.setSession("token", res?.results?.token);
        Session.setSession("user", res?.results);
        navigate('/');
      } else {
       setIsLoader(false)
        window.alert(res?.message);
      }
    } catch (error) {
      console.log('error in login api::', error);
     setIsLoader(false)
    }
  };

  const handleLoginFailure = (error) => {
    // Handle failed login
    console.error('Login failed. Error:', error);
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="authPage">
        <div className="authContainer">
          <a href="#" className="loginLogo"><img src="images/logo.png" alt="logo" /></a>
          <Form className="authForm" onSubmit={handleSubmit(handleLogin)}>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address"
                  }
                }}
                render={({ field }) => (
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    {...field}
                  />
                )}
              />
              {errors.email && <span className="error-message text-danger">{errors.email.message}</span>}
            </Form.Group>

            <Form.Group className="mb-4" controlId="formBasicPassword">
             <div className="d-flex align-items-center justify-content-between">
              <Form.Label>Password</Form.Label><a className="forgotPass pointer mb-2" onClick={handleForgotPassword}>Forgot Password</a>
             </div>
              <div className="password-input">
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "Password is required"
                  }}
                  render={({ field }) => (
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      {...field}
                    />
                  )}
                />
                <div className="password-toggle-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                </div>
              </div>
              {errors.password && <span className="error-message text-danger">{errors.password.message}</span>}
            </Form.Group>

            <Form.Group className="mb-4 remamberMe" controlId="formBasicCheckbox">

              <FormCheck
                type="checkbox"
                label="Remember me"
                checked={rememberMe}
                onChange={handleRememberMeChange}
                className="ps-0 mb-2"
              /> 

             <div className="d-flex">
             <FormCheck
                type="checkbox"
                // label="T&c"
                checked={check}
                onChange={()=>setCheck(!check)}
                className="ps-0"
              />
               <a onClick = {()=>navigate('/term_of_service')}>T&C</a>
             </div>

            </Form.Group>

            <div className="loginWith">
              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
              />
            </div>

            <Button type="submit" className="authAction themeBtn">
              Sign In
            </Button>
          </Form>
          {isLoader&&<PageLoader/>}
          {/* <div className="text-center pt-3">Don't have an account? <a href="/register">Create one</a></div> */}
        </div>
      </div>
    </>
  );
};

export default Login;
