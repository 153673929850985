// const USER_BASE_URL = 'http://localhost:2025/v1/user/';
// const ADMIN_BASE_URL = 'http://localhost:2025/v1/admin/';
// 
const USER_BASE_URL = 'https://api.stellaredgecapital.com/v1/user/';
const ADMIN_BASE_URL = 'https://api.stellaredgecapital.com/v1/admin/';


const apiPath = {
  // Auth paths
  login: USER_BASE_URL + "login",
  socialLogin: USER_BASE_URL + "social-login",
  logout: USER_BASE_URL + "logout",
  updateProfile: USER_BASE_URL + "update-profile",
  profile: USER_BASE_URL + "profile",

  sendPaymentOTP: USER_BASE_URL + "send-payment-otp",
  resetPassword: USER_BASE_URL + "reset-password",
  forgotPassword: USER_BASE_URL + "forgot-password",
  userList: USER_BASE_URL + "user-list",
  addSupport: ADMIN_BASE_URL + "support",
  addPayment: ADMIN_BASE_URL + "transaction",
  getImageURL: ADMIN_BASE_URL + "transaction/get-url",
  changePassword: USER_BASE_URL + "users/changePassword",

  userTransactionList: ADMIN_BASE_URL + "transaction/user-transaction",

};

export default apiPath;
