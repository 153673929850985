import { isEmpty, startCase } from "lodash";
import { AiFillEye } from "react-icons/ai";
import { NavLink, useLocation } from "react-router-dom";
import { React, useEffect, useState } from "react";
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, Table, Pagination  } from "react-bootstrap"; 

const WithdrawalTable = ({
  setSelectedUserId,
  users,
  getAllUsers,
  handleUserView,
  user,
  manager,
  page,
  report,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id");
  const handelStatusChange = async (item) => {
   
  };


  const renderMobileNumbers = (item) => (
    <>
      <div>{item.mobile || "N/A"}</div>
    </>
  );


  const handleDownLine=(data)=>{
    setSelectedUserId(data?._id)
  }

  useEffect(()=>{
    setSelectedUserId(userId)
  },[userId])



  return (
    <div className="TeamTable px-4"> 
    <Table bordered hover responsive className="themeTable">
          <thead>
            <tr>
              <th>S/No.</th>
              <th>Full name</th>
              <th>Referred User Name</th>
              <th>Referred User Email</th>
              <th>Amount</th>
              <th>Transaction Type</th>
              <th>Created on</th>
              <th>Status</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>

          {users?.map((user, i)=>{
            return(
            <tr key = {i}>
              <td>{i+1}</td>
              <td className="cursor-pointer" onClick={()=>handleDownLine(user)} >{user?.name}</td>
              <td>{user?.referredUserName||"N/A"}</td>
              <td>{user?.referredUserEmail||"N/A"}</td>
              <td>{user?.amount}</td>
              <td>{user?.transactionType}</td>
              <td>{user?.createdAt}</td>
              <td>{user?.status}</td>
              {/* <td className="RowAction"><a href="#"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="cursor-pointer w-5 h-5 text-slate-600 dark:hover:text-white hover:text-blue-700" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M396 512a112 112 0 1 0 224 0 112 112 0 1 0-224 0zm546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"></path></svg></a></td> */}
            </tr>
            )
          })}

           
         </tbody>
    </Table>  
</div>
  );
};

export default WithdrawalTable;
