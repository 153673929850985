import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { PageLoader } from './PageLoader'

export const Footer = () => {

  const navigate = useNavigate()
  return (
    <>
      <footer>
          <Container>
              <Row>
                  <Col lg={4} sm={6}>
                      <div className='footerCols pe-lg-4'>
                          <h2>About Us</h2> 

                    <p>Thousands of families in India used to experience different financial crises due to their meager expenses. As a helping hand on your shoulders, here we are.

We are setting up a family, by which we can help you by giving the higher returns on your small investments. We are using advanced technology to make things happen for every one of us.

With your help, we are establishing great projects of the decade. So, why being late? Start investing in your future, Invest in Stellar Edge Capital, where your capital and our commitment combine to form a huge flow of success.</p>
                    
                      </div> 
                  </Col>

                  <Col lg={3} sm={6}>
                      <div className='footerCols'>
                          <h2>About Us</h2> 
                          <div className='quickLink'>
                            <a href='/'>Home</a>
                            <a href='/about'>About Us</a>
                            <a href='/investment-plan'>Investment Plan</a>
                            {/* <a href='/project'>Upcoming Project</a> */}
                            
                          </div>
                      </div> 
                  </Col>

                  <Col lg={3} sm={6}>
                      <div className='footerCols'>
                          <h2>About Us</h2> 
                          <div className='quickLink'> 
                            <a className='cursor-pointer' onClick={()=>navigate('/blog')}>Blog</a>
                            <a className='cursor-pointer' onClick={()=>navigate('/contact-us')} >Contact</a> 
                            <a className='cursor-pointer' onClick={()=>navigate('/privacypolice')} >Privacy Policy</a>
                            <a className='cursor-pointer' onClick={()=>navigate('/term_of_service')} >Terms of service</a> 
                          </div>
                      </div> 
                  </Col> 

                  <Col lg={2} sm={6}> 
                  <div className='footerCols'>
                    <h2>Social Share</h2> 
                          <div className='socialLink'> 
                            <a href='https://www.facebook.com/profile.php?id=61563583864312'><img src='images/facebook.png' alt='facebook' /></a>
                            <a href='https://x.com/edge_stell4963'><img src='images/twitter.png' alt='facebook' /></a>
                            <a href='/'><img src='images/youtube.png' alt='facebook' /></a>
                            <a href='https://www.instagram.com/stellaredge2024/'><img src='images/insta.png' alt='facebook' /></a> 
                          </div>
                          </div>
                  </Col>

              </Row> 
          </Container>
         <div className='copyright'>© 2024 <a href='#'>Stellar Edge Capital</a> All Rights Reserved.</div>
      </footer> 

    
      
    </>
  )
}
