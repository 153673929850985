import { React, useState } from "react";
import { Tabs, Tab, Offcanvas, Button, Form, Container, Row, Col, Table, Accordion } from "react-bootstrap"; 
import { Package } from "../component/Package";
// import { Sidebar } from "../component/Sidebar";

const InvestmentPlan = () => {
 
  return (
    <> 
        {/* <section className="aboutPage py-4 py-lg-5 bg-white">
             <Container>
                <Row className="align-items-center">
                  <Col md={6}>
                      <div className="sectionCaption">
                        <h2 className="AsTitle">Investment Plan</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>

                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </p>

                    </div>
                  </Col>

                  <Col md={6}>
                      <div className="AboutPIC">
                        <img src="images/investment.jpg" className="img-thumbnail" alt="about" />
                    </div>
                  </Col> 
                </Row> 
            </Container> 
        </section>  */}

      <Package />
    
 
    </>
  );
};
export default InvestmentPlan;
