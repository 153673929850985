import React from 'react'
import Spinner from 'react-bootstrap/Spinner';


export const PageLoader = () => {
  return (
    <>

      <div className="WebsiteLoader"> <Spinner animation="border" variant="danger"  /></div>
 
    </>
  )
}
