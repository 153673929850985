import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const Reward = () => {
  return (
    <>
        <section className="RewardSection">
            <Container>
               <div className="sectionTItle">
                    <h2 className="text-white">LEADERSHIP REWARDS</h2>
                    <p style={{color:"#ddd"}}>"This program not only recognizes but also rewards your leadership abilities. Depending on your achievements and contributions to the project, you will be able to gain significant incentives that will improve your experience, both personally and professionally. Each award is designed to deliver unforgettable moments and represent major milestones in your journey. The awards may differ from project to project, but they are always designed to honor your accomplishment.

Take advantage of these excellent possibilities, and you will not only walk away with physical prizes, but also with positive feelings of success and pride that demonstrate your diligence and leadership talents."</p>
               </div>


               <Row>
                  <Col md={4}>
                    <div className="leaderShipOffer">
                        <figure>
                            <span><img src="images/smartphone.jpg" alt="smartphone" /></span>
                            <figcaption>
                              <h3>7500 $</h3>
                              <p>SMARTPHONE</p>
                            </figcaption> 
                        </figure> 
                    </div> 
                  </Col>

                  <Col md={4}>
                    <div className="leaderShipOffer">
                        <figure>
                            <span><img src="images/dhubai.jpg" alt="dhubai" /></span>
                            <figcaption>
                              <h3>75000 $</h3>
                              <p>DUBAI TOUR + 500 $ </p>
                            </figcaption> 
                        </figure> 
                    </div> 
                  </Col>

                  <Col md={4}>
                    <div className="leaderShipOffer">
                        <figure>
                            <span><img src="images/europ.jpg" alt="europ" /></span>
                            <figcaption>
                              <h3>150000 $</h3>
                              <p>LUXURY TRIP FOR EUROPE COUNTRY + 1500 $</p>
                            </figcaption> 
                        </figure> 
                    </div> 
                  </Col>
                
               </Row>




            </Container>
         </section>
    </>
  )
}
